<template>
  <div style="flex: 1; padding: 12px">
    <div class="header">
      <div class="title">
        <div class="btn" @click="prev">
          <a-icon type="caret-left" theme="filled" />
        </div>

        <div class="value">{{ year }} 年 {{ month }} 月</div>
        <div class="btn" @click="next">
          <a-icon type="caret-right" theme="filled" />
        </div>
      </div>

      <div class="weeks">
        <div class="week">日</div>
        <div class="week">一</div>
        <div class="week">二</div>
        <div class="week">三</div>
        <div class="week">四</div>
        <div class="week">五</div>
        <div class="week">六</div>
      </div>
    </div>

    <div class="calendar">
      <div class="day" v-for="item in empty" :key="item"></div>
      <div
        class="day"
        v-for="item in filteredDays"
        :key="String(year) + month + item.date"
        :class="item.date === date ? 'today' : ''"
        @click="openDetail(item)"
      >
        <div class="value">{{ item.date }}</div>

        <template v-if="item.list">
          <div
            class="tag"
            v-for="(tag, index) in item.list"
            :key="String(year) + month + item.date + index"
            :class="tag.color"
          >
            <span>{{ tag.title }}</span>
          </div>
        </template>
        <template v-if="item.adjustList">
          <div
            class="tag"
            v-for="(tag, index) in item.adjustList"
            :key="String(year) + month + item.date + index"
            :class="tag.color"
          >
            <span>{{ tag.title }}</span>
          </div>
        </template>
      </div>
    </div>

    <a-modal
      :title="`${year}年${month}月${detail.date ? detail.date : ''}日`"
      :visible="visible"
      :footer="null"
      @cancel="cancel"
    >
      <a-table bordered :data-source="getClockName(detail)" :pagination="false">
        <!-- <a-table-column title="打卡时间" data-index="time" align="center" /> -->
        <a-table-column title="描述" data-index="title" />
        <a-table-column title="详情">
          <template slot-scope="text">
            <span v-if="text.stype == 'on_business_out'">
              {{ text.startTime }}至{{ text.endTime }}
              <span style="margin-left: 10px;">同行人员：</span>
              <span v-for="item in text.staffList">
                {{ item.userName }}
              </span>
            </span>
            <span v-else>{{ text.reason }}</span>
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a v-if="text.stype == 'on_business_out' && text.taskId" @click="
                  $router.push(
                    `/approval/task/detail?taskId=${text.taskId}&instanceId=${text.instanceId}`
                  )
                ">审批详情</a>
            <a
              v-if="canReplace(text)"
              href="#"
              @click.prevent="replace(text.type)"
              >补卡</a
            >
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  fetchUserClock,
  fetchUserCheckApply,
  reviewMore
} from "@/api/human-resources/clock";
import moment from "moment";
import { getName, isLeapYear } from "@/utils/clock";
import holidayMixin from "../utils/index";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    overtimeList: {
      type: Array,
      default: () => [],
    },
    daysoffList: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [holidayMixin],

  data() {
    return {
      currentMonth: 0,

      year: 0,
      month: 0,
      date: 0,

      empty: 0,

      days: [],

      clockList: [],
      replaceList: [],
      overtimeList: [],
      daysoffList: [],
      detail: {},
      visible: false,
    };
  },

  computed: {
    // 先判断是否是假期
    // 再判断是否已经补过卡
    // 再判断是否已经请假出差

    // 再判断昨天是否加班，加班了迟到的判定条件就会宽容
    // 再判断用户有没有打卡，打卡有没有迟到早退

    // 如果用户在哺乳假期间，可以选择晚来早退，共计60分钟
    ...mapState("auth", ["user"]),
    filteredDays() {
      return this.days.map(({ date, week }) => {
        const d = new Date(this.year, this.month - 1, date);
        const thisDate = moment(d);

        const status = this.isHoliday(thisDate);
        const adjustList = this.getAdjustTag(thisDate, 'overtime')
        if (status === "holiday") {
          return { date, week, adjustList };
        } else {
          if (status === "workday" || !this.isWeekend(thisDate)) {
            const tags = this.getTag(thisDate);
            const days_off = this.getAdjustTag(thisDate, 'days_off')
            const feedObj = tags.find(
              (item) =>
                item.title === "哺乳假" &&
                item.date === thisDate.format("YYYY-MM-DD")
            );

            const otherObj = tags.find(
              (item) =>
                item.title !== "哺乳假" &&
                item.date === thisDate.format("YYYY-MM-DD")
            );
            
            let clocks = this.getClockStatus(thisDate, feedObj, otherObj);
            clocks = this.getClockStatusCompareDaysOff(thisDate, clocks, days_off);
            const replaces = this.getDateReplcae(thisDate);
            
            let list = [...clocks, ...tags, ...replaces, ...days_off];
            let morning = list.filter((item) => item.type === "morning");
            // 上午迟到未打卡如果有上午的补卡记录或请求出差记录，直接替换
            const greenMorning = morning.filter(
              (item) => item.color === "green"
            );
            if (greenMorning.length > 0) {
              morning = greenMorning;
            }

            let afternoon = list.filter((item) => item.type === "afternoon");

            // 下午迟到未打卡如果有下午的补卡记录或请求出差记录，直接替换

            const greenAfternoon = afternoon.filter(
              (item) => item.color === "green"
            );
            if (greenAfternoon.length > 0) {
              afternoon = greenAfternoon;
            }

            // 整天假期
            const others = list.filter(
              (item) => !item.type && item.color === "green" || item.type === "days_off"
            );
            const noFeed = others.filter((item) => item.title !== "哺乳假" && item.type !== "days_off");
            if (noFeed.length > 0) {
              // 有了除哺乳假之外的其他整天假期，那就不用显示其他的上班/下班迟到早退未打卡了，如果有半天的假期，也会被隐藏，这个无所谓，理论上假期不允许重叠。
              list = others;
            } else {
              list = [...morning, ...afternoon, ...others];
            }

            return Object.freeze({
              date,
              week,
              list,
              adjustList
            });
          } else {
            return {
              date,
              week,
              adjustList
            };
          }
        }
      });
    },
  },

  mounted() {
    let today = new Date();

    this.currentMonth = today.getMonth() + 1;

    this.year = today.getFullYear();
    this.month = this.currentMonth;
    this.date = today.getDate();
    this.init();
  },

  methods: {
    prev() {
      this.init("prev");
    },

    next() {
      this.init("next");
    },

    init(type) {
      if (type === "prev") {
        if (this.month === 1) {
          this.year = this.year - 1;
          this.month = 12;
        } else {
          this.month = this.month - 1;
        }
      } else if (type === "next") {
        if (this.month === 12) {
          this.year = this.year + 1;
          this.month = 1;
        } else {
          this.month = this.month + 1;
        }
      }

      this.$emit("change", {
        year: this.year,
        month: this.month,
      });

      this.empty = new Date(this.year, this.month - 1, 1).getDay();

      let days = 0;

      if ([1, 3, 5, 7, 8, 10, 12].indexOf(this.month) > -1) {
        days = 31;
      } else if ([4, 6, 9, 11].indexOf(this.month) > -1) {
        days = 30;
      } else if (this.month === 2) {
        if (isLeapYear(this.year)) {
          days = 29;
        } else {
          days = 28;
        }
      }

      const arr = [];
      for (let i = 1; i <= days; i++) {
        arr.push({
          date: i,
          week: new Date(this.year, this.month - 1, i).getDay(),
        });
      }
      this.days = arr;
      this.getClock();
      this.getReplace();
    },
    getAdjustTag(thisDate, type) {
      const tags = [];
      if(type == 'overtime'){
        let arr = []
        this.overtimeList.forEach((v)=>{
          if(v.extra){
            const extra = JSON.parse(v.extra)
            extra.forEach(item=>{
              arr.push({
                ...v,
                startTime: item.date + ' ' + item.startTime,
                endTime: item.date + ' ' + item.endTime,
                effectiveStatus: item.effectiveStatus
              })
            })
          }
        })
        arr.forEach(item=>{
          const startDateObject = moment(item.startTime, "YYYY-MM-DD");
          const endDateObject = moment(item.endTime, "YYYY-MM-DD");
          if (
            thisDate.isAfter(startDateObject, "day") &&
            thisDate.isBefore(endDateObject, "day") || thisDate.isSame(startDateObject, "day") || thisDate.isSame(endDateObject, "day")
          ) {
            tags.unshift({
              title:moment(item.startTime).format("HH:mm") + '-' + moment(item.endTime).format("HH:mm")  + getName(item.type) + this.getStatus(item.status) + (item.effectiveStatus=='invalid' && item.status=='approved'?'待生效':''),
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
            });
          }
        })
      }
      if(type == 'days_off'){
        let arr = []
        this.daysoffList.forEach((v)=>{
          if(v.extra){
            const extra = JSON.parse(v.extra)
            extra.forEach(item=>{
              arr.push({
                ...v,
                startTime: item.date + ' ' + item.startTime,
                endTime: item.date + ' ' + item.endTime
              })
            })
          }
        })
        arr.forEach(item=>{
          const startDateObject = moment(item.startTime, "YYYY-MM-DD");
          const endDateObject = moment(item.endTime, "YYYY-MM-DD");
          if (
            thisDate.isAfter(startDateObject, "day") &&
            thisDate.isBefore(endDateObject, "day") || thisDate.isSame(startDateObject, "day") || thisDate.isSame(endDateObject, "day")
          ) {
            if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 09:00:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 17:30:00"
              )
            ){
              tags.unshift({
                title: getName(item.type) + this.getStatus(item.status),
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            }  else if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 09:00:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 12:00:00"
              )
            ) {
              // 开始和结束时间包含了9点，说明上午在调休
              tags.unshift({
                title:
                  "上午" + getName(item.type) + this.getStatus(item.status),
                type: "morning",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            } else if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 13:30:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 17:30:00"
              )
            ) {
              // 开始和结束时间包含了下午5点半，说明下午在调休
              tags.unshift({
                title:
                  "下午" + getName(item.type) + this.getStatus(item.status),
                type: "afternoon",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            } else {
              tags.unshift({
                title:moment(item.startTime).format("HH:mm") + '-' + moment(item.endTime).format("HH:mm")  + getName(item.type) + this.getStatus(item.status),
                type: "days_off",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
                startTime: thisDate.format("YYYY-MM-DD") + ' ' + moment(item.startTime).format("HH:mm"),
                endTime: thisDate.format("YYYY-MM-DD") + ' ' + moment(item.endTime).format("HH:mm")
              });
              console.log(
                `调休在${thisDate.format("YYYY-MM-DD")}内的期间出现，不参与考勤`
              );
            }
          }
        })
      }
      return tags
    },
    // 获取考勤数据
    getClock() {
      const hide = this.$message.loading("加载中...");
      let month = this.month;
      if (month < 10) {
        month = "0" + month;
      }
      fetchUserClock({
        workDate: `${this.year}-${month}`,
      })
        .then((res) => {
          if (
            Array.isArray(res) &&
            res.length > 0 &&
            Array.isArray(res[0].attList)
          ) {
            this.clockList = Object.freeze(
              res[0].attList.map((item) => {
                return {
                  ...item,
                  attGroupId: res[0].attGroupId,
                };
              })
            );
          }
        })
        .finally(() => {
          hide();
        });
    },
    
    getClockStatus(thisDate, feedObj, otherObj) {
      const thisDateStr = thisDate.format("YYYY-MM-DD");
      const yesterdayStr = thisDate
        .clone()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      // 可以直接获取到上月最后一天
      // 周一暂时不考虑，打工人都休息2天了，还要啥合法迟到

      const today = moment();

      let notGoOff = false;
      if (thisDate.isSame(today, "days")) {
        if (today.isBefore(thisDateStr + " 08:30:00", "minutes")) {
          console.log("还没到上班时间");
          return [];
        } else if (today.isBefore(thisDateStr + " 17:30:00", "minutes")) {
          console.log("还没到下班时间");
          notGoOff = true;
        }
      }
      // 日子还没到，置空
      if (thisDate.isAfter(today, "days")) {
        return [];
      }

      let tags = [];

      for (let i = 0; i < this.clockList.length; i++) {
        const item = this.clockList[i];
        if (item.workDate === thisDateStr) {
          if (item.checkType === "OnDuty") {
            const yesterday = this.clockList.find(
              (element) =>
                element.workDate === yesterdayStr &&
                element.checkType === "OffDuty"
            );
            // 钉钉返回的数据正常的 OffDuty，但打卡时间是次日凌晨
            let isSecond = false;

            if (yesterday) {
              isSecond = moment(yesterday.userCheckTime).isAfter(
                yesterdayStr,
                "days"
              );
            }

            const overtime = this.clockList.find(
              (element) =>
                element.workDate === thisDateStr &&
                element.checkType === "OverTime"
            );

            if (overtime) {
              console.log(thisDateStr + "有凌晨加班" + overtime.userCheckTime);
            }

            tags.push({
              ...item,
              yesterday: overtime
                ? {
                    ...overtime,
                    isSecond: true,
                  }
                : yesterday
                ? {
                    ...yesterday,
                    isSecond,
                  }
                : undefined,
            });
          } else if (item.checkType === "OffDuty") {
            tags.push({
              ...item,
            });
          }
        }

        if (
          moment(item.userCheckTime)
            .subtract(1, "days")
            .format("YYYY-MM-DD") === thisDateStr &&
          item.checkType === "OverTime"
        ) {
          console.log("catch you");
          const userCheckTime = moment(item.userCheckTime);

          if (
            userCheckTime.isSameOrBefore(
              userCheckTime.format("YYYY-MM-DD") + " 06:00:00",
              "seconds"
            )
          ) {
            const index = tags.findIndex((tag) => tag.checkType === "OffDuty");
            if (index > -1) {
              tags.splice(index, 1, {
                ...item,
                workDate: thisDateStr,
                userCheckTime: item.userCheckTime,
                checkType: "OffDuty",
              });
            } else {
              tags.push({
                ...item,
                workDate: thisDateStr,
                userCheckTime: item.userCheckTime,
                checkType: "OffDuty",
              });
            }
          }
        }
      }

      if (tags.length > 0) {
        let goToWorkTime = "";
        let goToWorkStatus = "";
        let goToWorkStatusReason = "";
        let goOffWorkTime = "";
        let goOffWorkStatus = "";
        let goOffWorkStatusReason = "";

        const goToWorkTimeObject = moment(
          thisDateStr + " 09:00:00",
          "YYYY-MM-DD HH:mm:ss"
        );

        const goToWorkTimeLate5Object = moment(
          thisDateStr + " 09:05:00",
          "YYYY-MM-DD HH:mm:ss"
        );
        const goToWorkTimeLate30Object = moment(
          thisDateStr + " 09:30:00",
          "YYYY-MM-DD HH:mm:ss"
        );

        const goToWorkTimeLate10Object = moment(
          thisDateStr + " 10:00:00",
          "YYYY-MM-DD HH:mm:ss"
        );

        const goToWorkTimeHalfDayObject = moment(
          thisDateStr + " 13:30:00",
          "YYYY-MM-DD HH:mm:ss"
        );

        const goOffWorkTimeObject = moment(
          thisDateStr + " 17:30:00",
          "YYYY-MM-DD HH:mm:ss"
        );
        const goOffWorkTimeEarlyObject = moment(
          thisDateStr + " 17:00:00",
          "YYYY-MM-DD HH:mm:ss"
        );
        const goOffWorkTimeEarly5Object = moment(
          thisDateStr + " 17:25:00",
          "YYYY-MM-DD HH:mm:ss"
        );

        if (feedObj) {
          // 如果这个人有哺乳假，上下班有40分钟累计，但下午又请假了，这时候就单独判断，下面的逻辑是不带假期的
          // 如果是一整天假期，那就直接把下面的情况覆盖了，如果是半天假期，下面的逻辑就不对了。
          // 比如出现一种情况，一个女生9点30上班，中午11点半走了，下午请了病假，这样页面上就会显示上班异常，下午病假。
          // 但应该中午的下班时间不参与1小时弹性计算了
          // 所以只需要判断上午或下午是否超过一小时即可

          // 如果有哺乳假，那单独走这套判断逻辑

          let comeDate = "";
          let goDate = "";
          for (let i = 0; i < tags.length; i++) {
            const item = tags[i];
            if (item.checkType === "OnDuty") {
              comeDate = item.userCheckTime;
            } else if (item.checkType === "OffDuty") {
              goDate = item.userCheckTime;
            }
          }

          if (otherObj) {
            // 如果这个人这天有假期，如果是一天的假期，页面上会直接覆盖，其实不用处理，如果是半天的假期，就需要判断了。
            if (otherObj.title.indexOf("上午") > -1) {
              // 上午的假期，判断下午是否早退即可，如果没打卡，这个函数结束之前会走判断逻辑
              if (goDate) {
                // 打了下班卡
                const goDiff = moment(goDate).diff(
                  goOffWorkTimeObject,
                  "minutes"
                );
                if (goDiff < 0) {
                  // 下班早退

                  if (Math.abs(goDiff) > 60) {
                    return [
                      {
                        title: "下班异常",
                        color: "red",
                        time: goDate,
                        reason: "哺乳假期间",
                        type: "afternoon",
                        category: "clock",
                      },
                    ];
                  } else {
                    return [
                      {
                        title: "下班正常",
                        color: "green",
                        time: goDate,
                        reason: "哺乳假期间",
                        type: "afternoon",
                        category: "clock",
                      },
                    ];
                  }
                } else {
                  return [
                    {
                      title: "下班正常",
                      color: "green",
                      time: goDate,
                      reason: "哺乳假期间",
                      type: "afternoon",
                      category: "clock",
                    },
                  ];
                }
              }
            } else if (otherObj.title.indexOf("下午") > -1) {
              if (comeDate) {
                const comeDiff = moment(comeDate).diff(
                  goToWorkTimeObject,
                  "minutes"
                );

                if (comeDiff > 0) {
                  if (comeDiff > 60) {
                    console.log(comeDate + "出发了半天假期的上班异常");
                    return [
                      {
                        title: "上班异常",
                        color: "red",
                        time: comeDate,
                        reason: "哺乳假期间",
                        type: "morning",
                        category: "clock",
                      },
                    ];
                  } else {
                    return [
                      {
                        title: "上班正常",
                        color: "green",
                        time: comeDate,
                        reason: "哺乳假期间",
                        type: "morning",
                        category: "clock",
                      },
                    ];
                  }
                } else {
                  return [
                    {
                      title: "上班正常",
                      color: "green",
                      time: comeDate,
                      reason: "哺乳假期间",
                      type: "morning",
                      category: "clock",
                    },
                  ];
                }
              }
            }
          }

          if (comeDate && goDate) {
            // 判断上班时间和9点的分钟差值，如果早于9点是负数
            const comeDiff = moment(comeDate).diff(
              goToWorkTimeObject,
              "minutes"
            );
            const goDiff = moment(goDate).diff(goOffWorkTimeObject, "minutes");

            if (comeDiff <= 0 && goDiff >= 0) {
              // 上下班都正常
              return [
                {
                  title: "上班正常",
                  color: "green",
                  time: comeDate,
                  reason: "",
                  type: "morning",
                  category: "clock",
                },
                {
                  title: "下班正常",
                  color: "green",
                  time: goDate,
                  reason: "",
                  type: "afternoon",
                  category: "clock",
                },
              ];
            } else if (comeDiff > 0 && goDiff >= 0) {
              // 下班正常，上班迟到
              let obj;
              if (comeDiff > 60) {
                obj = {
                  title: "上班异常",
                  color: "red",
                  time: comeDate,
                  reason: "哺乳假期间",
                  type: "morning",
                  category: "clock",
                };
              } else {
                obj = {
                  title: "上班正常",
                  color: "green",
                  time: comeDate,
                  reason: "哺乳假期间",
                  type: "morning",
                  category: "clock",
                };
              }

              return [
                obj,
                {
                  title: "下班正常",
                  color: "green",
                  time: goDate,
                  reason: "",
                  type: "afternoon",
                  category: "clock",
                },
              ];
            } else if (comeDiff <= 0 && goDiff < 0) {
              // 下班早退，上班正常
              let obj;

              if (Math.abs(goDiff) > 60) {
                obj = {
                  title: "下班异常",
                  color: "red",
                  time: goDate,
                  reason: "哺乳假期间",
                  type: "afternoon",
                  category: "clock",
                };
              } else {
                obj = {
                  title: "下班正常",
                  color: "green",
                  time: goDate,
                  reason: "哺乳假期间",
                  type: "afternoon",
                  category: "clock",
                };
              }

              return [
                {
                  title: "上班正常",
                  color: "green",
                  time: comeDate,
                  reason: "",
                  type: "morning",
                  category: "clock",
                },
                obj,
              ];
            } else {
              // 上下班都有问题
              const diff = comeDiff + Math.abs(goDiff);
              if (diff > 60) {
                return [
                  {
                    title: "上班异常",
                    color: "red",
                    time: comeDate,
                    reason: "哺乳假期间",
                    type: "morning",
                    category: "clock",
                  },
                  {
                    title: "下班正常",
                    color: "green",
                    time: goDate,
                    reason: "哺乳假期间",
                    type: "afternoon",
                    category: "clock",
                  },
                ];
              } else {
                return [
                  {
                    title: "上班正常",
                    color: "green",
                    time: comeDate,
                    reason: "哺乳假期间",
                    type: "morning",
                    category: "clock",
                  },
                  {
                    title: "下班正常",
                    color: "green",
                    time: goDate,
                    reason: "哺乳假期间",
                    type: "afternoon",
                    category: "clock",
                  },
                ];
              }
            }
          } else if (comeDate && !goDate) {
            // 判断上班时间和9点的分钟差值，如果早于9点是负数
            const comeDiff = moment(comeDate).diff(
              goToWorkTimeObject,
              "minutes"
            );
            let obj;

            if (comeDiff > 60) {
              obj = {
                title: "上班异常",
                color: "red",
                time: comeDate,
                reason: "哺乳假期间",
                type: "morning",
                category: "clock",
              };
            } else {
              obj = {
                title: "上班正常",
                color: "green",
                time: comeDate,
                reason: "哺乳假期间",
                type: "morning",
                category: "clock",
              };
            }

            return [
              obj,
              {
                title: "下班未打卡",
                color: "red",
                type: "afternoon",
                category: "clock",
              },
            ];
          } else if (!comeDate && goDate) {
            const goDiff = moment(goDate).diff(goOffWorkTimeObject, "minutes");
            let obj;

            if (goDiff < 0 && Math.abs(goDiff) > 60) {
              obj = {
                title: "下班异常",
                color: "red",
                time: goDate,
                reason: "哺乳假期间",
                type: "afternoon",
                category: "clock",
              };
            } else {
              obj = {
                title: "下班正常",
                color: "green",
                time: goDate,
                reason: "哺乳假期间",
                type: "afternoon",
                category: "clock",
              };
            }

            return [
              {
                title: "上班未打卡",
                color: "red",
                type: "morning",
                category: "clock",
              },
              obj,
            ];
          } else {
            console.log("checkType 没匹配上，不可能发生");
            return [];
          }
        }

        for (let i = 0; i < tags.length; i++) {
          const item = tags[i];

          const userCheckTime = moment(
            item.userCheckTime,
            "YYYY-MM-DD HH:mm:ss"
          );

          if (item.checkType === "OnDuty") {
            goToWorkTime = item.userCheckTime;

            if (userCheckTime.isSameOrBefore(goToWorkTimeObject, "minutes")) {
              goToWorkStatus = "上班正常";
            } else {
              let newTime = "";

              const { userCheckTime: yesterdayUserCheckTime, isSecond } =
                item.yesterday || {};

              if (yesterdayUserCheckTime) {
                const yesterdayUserCheckTimeObj = moment(
                  yesterdayUserCheckTime
                );

                let yesterdayCheckTimeObj; // 加班判断依据

                if (isSecond) {
                  yesterdayCheckTimeObj = moment(
                    yesterdayUserCheckTime
                  ).subtract(1, "days");
                } else {
                  yesterdayCheckTimeObj = moment(yesterdayUserCheckTime);
                }

                console.log(
                  "yesterdayCheckTimeObj",
                  yesterdayCheckTimeObj.format("YYYY-MM-DD")
                );

                const yesterday2130 = moment(
                  yesterdayCheckTimeObj.format("YYYY-MM-DD") + " " + "21:00:00",
                  "YYYY-MM-DD HH:mm:ss"
                );
                const yesterday2200 = moment(
                  yesterdayCheckTimeObj.format("YYYY-MM-DD") + " " + "22:00:00",
                  "YYYY-MM-DD HH:mm:ss"
                );
                const yesterday2300 = moment(
                  yesterdayCheckTimeObj.format("YYYY-MM-DD") + " " + "23:00:00",
                  "YYYY-MM-DD HH:mm:ss"
                );
                const yesterday2359 = moment(
                  yesterdayCheckTimeObj.format("YYYY-MM-DD") + " " + "23:59:59",
                  "YYYY-MM-DD HH:mm:ss"
                );

                if (yesterdayUserCheckTimeObj.isSameOrAfter(yesterday2130)) {
                  if (yesterdayUserCheckTimeObj.isBefore(yesterday2200)) {
                    console.log("下班时间在9点到10点之间");
                    newTime = "09:30";
                  } else if (
                    yesterdayUserCheckTimeObj.isBefore(yesterday2300)
                  ) {
                    console.log("下班时间在10点到11点之间");
                    newTime = "10:00";
                  } else if (
                    yesterdayUserCheckTimeObj.isSameOrBefore(yesterday2359)
                  ) {
                    console.log("下班时间在11点到12点之间");
                    newTime = "11:00";
                  } else {
                    console.log("下班时间在12点之后，不知道钉钉可不可以打卡");
                    newTime = "13:30";
                  }
                }
              }
              if (newTime) {
                if (newTime === "09:30") {
                  if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate30Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "上班正常";
                    goToWorkStatusReason = `昨天晚上上班至(${yesterdayUserCheckTime})`;
                  } else if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate10Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "迟到";
                    goToWorkStatusReason = "迟到";
                  } else if (
                    userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                  ) {
                    goToWorkStatus = "上午旷工半日";
                    goToWorkStatusReason = "13:30之前打上班卡，算旷工半日";
                  } else {
                    goToWorkStatus = "旷工一日";
                    goToWorkStatusReason = "13:30之后打上班卡，算旷工一日";
                  }
                } else if (newTime === "10:00") {
                  if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate10Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "上班正常";
                    goToWorkStatusReason = `昨天晚上上班至(${yesterdayUserCheckTime})`;
                  } else if (
                    userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                  ) {
                    goToWorkStatus = "上午旷工半日";
                    goToWorkStatusReason = "13:30之前打上班卡，算旷工半日";
                  } else {
                    goToWorkStatus = "旷工一日";
                    goToWorkStatusReason = "13:30之后打上班卡，算旷工一日";
                  }
                } else if (newTime === "11:00") {
                  const condition = thisDateStr + " 11:00:00";

                  if (userCheckTime.isSameOrBefore(condition, "minutes")) {
                    goToWorkStatus = "上班正常";
                    goToWorkStatusReason = `昨天晚上上班至(${yesterdayUserCheckTime})`;
                  } else if (
                    userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                  ) {
                    goToWorkStatus = "上午旷工半日";
                    goToWorkStatusReason = "13:30之前打上班卡，算旷工半日";
                  } else {
                    goToWorkStatus = "旷工一日";
                    goToWorkStatusReason = "13:30之后打上班卡，算旷工一日";
                  }
                } else {
                  goToWorkStatus = "上午不用上班";
                  goToWorkStatusReason = `昨天晚上上班至(${yesterdayUserCheckTime})`;
                }
              } else {
                if (
                  typeof item.attGroupId === "string" &&
                  item.attGroupId.indexOf("org_product") > -1 &&
                  userCheckTime.isBefore("2023-09-01", "days")
                ) {
                  // 生产部门的人上班判断逻辑
                  console.log("生产部门的人上班判断逻辑");

                  if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate30Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "上班正常";
                    goToWorkStatusReason = "生产部门9点半之前算正常";
                  } else if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate10Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "迟到";
                    goToWorkStatusReason = "迟到";
                  } else if (
                    userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                  ) {
                    goToWorkStatus = "上午旷工半日";
                    goToWorkStatusReason = "13:30之前打上班卡，算旷工半日";
                  } else {
                    goToWorkStatus = "旷工一日";
                    goToWorkStatusReason = "13:30之后打上班卡，算旷工一日";
                  }
                } else {
                  // 普通员工的上班判断逻辑
                  if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate5Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "迟到";
                    goToWorkStatusReason = "5分钟内迟到";
                  } else if (
                    userCheckTime.isSameOrBefore(
                      goToWorkTimeLate10Object,
                      "minutes"
                    )
                  ) {
                    goToWorkStatus = "迟到";
                    goToWorkStatusReason = "迟到";
                  } else if (
                    userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                  ) {
                    goToWorkStatus = "上午旷工半日";
                    goToWorkStatusReason = "13:30之前打上班卡，算旷工半日";
                  } else {
                    goToWorkStatus = "旷工一日";
                    goToWorkStatusReason = "13:30之后打上班卡，算旷工一日";
                  }
                }
              }
            }
          } else if (item.checkType === "OffDuty") {
            goOffWorkTime = item.userCheckTime;

            if (userCheckTime.isSameOrAfter(goOffWorkTimeObject)) {
              goOffWorkStatus = "下班正常";
            } else {
              // 普通员工的下班判断逻辑

              if (
                userCheckTime.isBefore(goOffWorkTimeObject) &&
                userCheckTime.isSameOrAfter(goOffWorkTimeEarly5Object)
              ) {
                goOffWorkStatus = "早退";
                goOffWorkStatusReason = "5点25分之后5点半之前打卡，算早退5分钟";
              } else if (
                userCheckTime.isBefore(goOffWorkTimeObject) &&
                userCheckTime.isSameOrAfter(goOffWorkTimeEarlyObject)
              ) {
                goOffWorkStatus = "早退";
                goOffWorkStatusReason = "5点之后5点半之前打卡，算早退";
              } else {
                (goOffWorkStatus = "下午旷工半日"),
                  (goOffWorkStatusReason = "5点之前下班，算旷工半日");
              }
            }
          }
        }

        // 如果有一个没值，就是没打卡

        const arr = [];

        if (!goToWorkStatus) {
          arr.push({
            title: "上班未打卡",
            type: "morning",
            color: "red",
            category: "clock",
          });
        } else {
          arr.push({
            title: goToWorkStatus,
            color:
              goToWorkStatus.indexOf("正常") > -1 ||
              goToWorkStatus.indexOf("不用上班") > -1
                ? "green"
                : "red",
            time: goToWorkTime,
            reason: goToWorkStatusReason,
            type: "morning",
            category: "clock",
          });
        }

        if (!notGoOff) {
          if (!goOffWorkStatus) {
            arr.push({
              title: "下班未打卡",
              color: "red",
              type: "afternoon",
              category: "clock",
            });
          } else {
            arr.push({
              title: goOffWorkStatus,

              color:
                goOffWorkStatus.indexOf("正常") > -1 ||
                goOffWorkStatus.indexOf("不用上班") > -1
                  ? "green"
                  : "red",
              time: goOffWorkTime,
              reason: goOffWorkStatusReason,
              type: "afternoon",
              category: "clock",
            });
          }
        }

        return arr;
      } else {
        const arr = [
          {
            title: "上班未打卡",
            color: "red",
            type: "morning",
            category: "clock",
          },
        ];

        if (!notGoOff) {
          arr.push({
            title: "下班未打卡",
            color: "red",
            type: "afternoon",
            category: "clock",
          });
        }

        return arr;
      }
    },
    getClockStatusCompareDaysOff(thisDate, clocks, daysOff) {
      const thisDateStr = thisDate.format("YYYY-MM-DD");
      const checkInfo = this.clockList.filter(v=>v.workDate === thisDateStr)
      clocks.forEach((item,index)=>{
        if(item.title == '迟到'){
          checkInfo.forEach(v=>{
            const userCheckTime =  moment(v.userCheckTime).format("YYYY-MM-DD HH:mm")
            if(v.checkType == 'OnDuty'){
              daysOff.forEach(el=>{
                if( moment(userCheckTime).isSameOrBefore(el.endTime) && moment(userCheckTime).isSameOrAfter(el.startTime)){
                  console.log(11111)
                  clocks[index].title = '上班正常'
                  clocks[index].color = 'green'
                }
              })
            }
          })
        }
        if(item.title == '早退'){
          checkInfo.forEach(v=>{
            const userCheckTime =  moment(v.userCheckTime).format("YYYY-MM-DD HH:mm")
            if(v.checkType == 'OffDuty'){
              daysOff.forEach(el=>{
                if( moment(userCheckTime).isSameOrBefore(el.endTime) && moment(userCheckTime).isSameOrAfter(el.startTime)){
                  console.log(22222)
                  clocks[index].title = '下班正常'
                  clocks[index].color = 'green'
                }
              })
            }
          })
        }
      })
      return clocks
    },
    getTag(thisDate) {
      const tags = [];

      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];

        const startDateObject = moment(item.startTime, "YYYY-MM-DD");
        const endDateObject = moment(item.endTime, "YYYY-MM-DD");

        // 没有假期出差
        if (
          thisDate.isBefore(startDateObject, "day") ||
          thisDate.isAfter(endDateObject, "day")
        ) {
          continue;
        }

        // 在假期出差时间范围内
        if (
          thisDate.isAfter(startDateObject, "day") &&
          thisDate.isBefore(endDateObject, "day")
        ) {
          tags.unshift({
            title: getName(item.type) + this.getStatus(item.status),
            color: "green",
            date: thisDate.format("YYYY-MM-DD"),
            id: item.id,
            stype: item.type
          });
          continue;
        }

        // 就一天假期
        if (
          thisDate.isSame(startDateObject, "day") &&
          thisDate.isSame(endDateObject, "day")
        ) {
          if (item.type === "on_business_in") {
            if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 09:00:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 17:30:00"
              )
            ) {
              // 开始和结束时间包括了9点和下午5点半，说明这一天都在公出

              tags.unshift({
                title: getName(item.type) + this.getStatus(item.status),
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
              continue;
            } else if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 09:00:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 09:00:00"
              )
            ) {
              // 开始和结束时间包含了9点，说明上午在公出
              tags.unshift({
                title:
                  "上午" + getName(item.type) + this.getStatus(item.status),
                type: "morning",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });

              continue;
            } else if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 17:30:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 17:30:00"
              )
            ) {
              // 开始和结束时间包含了下午5点半，说明下午在公出
              tags.unshift({
                title:
                  "下午" + getName(item.type) + this.getStatus(item.status),
                type: "afternoon",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });

              continue;
            } else {
              console.log(
                `公出在${thisDate.format("YYYY-MM-DD")}内的期间出现，不参与考勤`
              );
            }

            continue;
          }

          if (
            item.startTime.indexOf("09:00:00") > 0 &&
            item.endTime.indexOf("17:30:00") > 0
          ) {
            tags.unshift({
              title: getName(item.type) + this.getStatus(item.status),
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });
            continue;
          } else if (
            item.startTime.indexOf("09:00:00") > 0 &&
            item.endTime.indexOf("12:00:00") > 0
          ) {
            tags.unshift({
              title: "上午" + getName(item.type) + this.getStatus(item.status),
              type: "morning",
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });

            continue;
          } else if (
            item.startTime.indexOf("13:30:00") > 0 &&
            item.endTime.indexOf("17:30:00") > 0
          ) {
            tags.unshift({
              title: "下午" + getName(item.type) + this.getStatus(item.status),
              type: "afternoon",
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });
            continue;
          } else {
            console.log("never happend");
            continue;
          }
        }

        // 这是假期第一天
        if (thisDate.isSame(startDateObject, "day")) {
          if (item.type === "on_business_in") {
            if (
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 09:00:00"
              )
            ) {
              // 公出第一天，开始时间包括了9点，说明这一天都在公出
              tags.unshift({
                title: getName(item.type) + this.getStatus(item.status),
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            } else if (
              moment(item.startTime).isAfter(
                startDateObject.format("YYYY-MM-DD") + " 09:00:00"
              ) &&
              moment(item.startTime).isSameOrBefore(
                startDateObject.format("YYYY-MM-DD") + " 17:30:00"
              )
            ) {
              // 否则就是下午才开始公出
              tags.unshift({
                title:
                  "下午" + getName(item.type) + this.getStatus(item.status),
                type: "afternoon",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            } else {
              console.log("第一天是下午5点半之后，忽略这种情况");
            }

            continue;
          }

          if (item.startTime.indexOf("09:00:00") > 0) {
            tags.unshift({
              title: getName(item.type) + this.getStatus(item.status),
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });
            continue;
          } else if (item.startTime.indexOf("13:30:00") > 0) {
            tags.unshift({
              title: "下午" + getName(item.type) + this.getStatus(item.status),
              type: "afternoon",
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });
            continue;
          } else {
            console.log("never happend");
            continue;
          }
        }

        // 这是假期最后一天
        if (thisDate.isSame(endDateObject, "day")) {
          if (item.type === "on_business_in") {
            if (
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 17:30:00"
              )
            ) {
              // 公出最后一天，结束时间包括了下午5点半，说明这一天都在公出
              tags.unshift({
                title: getName(item.type) + this.getStatus(item.status),
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            } else if (
              moment(item.endTime).isBefore(
                endDateObject.format("YYYY-MM-DD") + " 17:30:00"
              ) &&
              moment(item.endTime).isSameOrAfter(
                endDateObject.format("YYYY-MM-DD") + " 09:00:00"
              )
            ) {
              // 否则就是上午结束了公出
              tags.unshift({
                title:
                  "上午" + getName(item.type) + this.getStatus(item.status),
                type: "morning",
                color: "green",
                date: thisDate.format("YYYY-MM-DD"),
              });
            } else {
              console.log("最后一天是9点之前，忽略掉这种情况");
            }
            continue;
          }

          if (item.endTime.indexOf("12:00:00") > 0) {
            tags.unshift({
              title: "上午" + getName(item.type) + this.getStatus(item.status),
              type: "morning",
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });
            continue;
          } else if (item.endTime.indexOf("17:30:00") > 0) {
            tags.unshift({
              title: getName(item.type) + this.getStatus(item.status),
              color: "green",
              date: thisDate.format("YYYY-MM-DD"),
              id: item.id,
              stype: item.type
            });
            continue;
          } else {
            console.log("never happend");
            continue;
          }
        }
      }

      return tags;
    },

    getStatus(status) {
      if (status === "reviewing") {
        return "审批中";
      } else if (status === "approved") {
        return "";
      } else {
        return "";
      }
    },

    getReplace() {
      const d = new Date(this.year, this.month - 1);
      const m = moment(d);
      const startTime = m.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime = m.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      fetchUserCheckApply({
        startTime,
        endTime,
        applyStatusList: ["reviewing", "approved"],
      }).then((res) => {
        if (Array.isArray(res)) {
          this.replaceList = res;
        }
      });
    },

    getDateReplcae(thisDate) {
      const tags = [];

      for (let i = 0; i < this.replaceList.length; i++) {
        const item = this.replaceList[i];
        const checkTime = moment(item.checkTime, "YYYY-MM-DD");
        if (checkTime.isSame(thisDate)) {
          if (item.checkType === "OnDuty") {
            tags.unshift({
              title: "上班已补卡",
              type: "morning",
              color: "green",
            });
          } else if (item.checkType === "OffDuty") {
            tags.unshift({
              title: "下班已补卡",
              type: "afternoon",
              color: "green",
            });
          }
        }
      }

      return tags;
    },

    async openDetail(item) {
      console.log(item)
      this.detail = item;
      item.list.forEach((v,index)=>{
        if(v.stype == 'on_business_out') {
          reviewMore({
            id:v.id,
            type:v.stype
          }).then(res=>{
            const obj = {
              ...this.detail.list[index],
              taskId: res.taskId,
              instanceId: res.instanceId,
              startTime: res.startTime,
              endTime: res.endTime,
              staffList: res.staffList

            }
            this.detail.list.splice(index, 1, obj)
            this.visible = true;
          })
        }else{
          this.visible = true;
        }
      })
    },
    cancel() {
      this.detail = {};
      this.visible = false;
    },

    getClockName(detail) {
      if (Array.isArray(detail.list)) {
        return detail.list;
        // return detail.list.filter((item) => item.category === "clock");
      }
      return [];
    },

    // 申请补卡
    replace(type) {
      let checkType = "";
      if (type === "morning") {
        checkType = "OnDuty";
      } else if (type === "afternoon") {
        checkType = "OffDuty";
      }
      this.$router.push(
        this.$route.path +
          "/replace" +
          `?year=${this.year}&month=${this.month}&date=${this.detail.date}&checkType=${checkType}`
      );
    },

    canReplace(text) {
      // .add(5, "days")
      const today = moment().format("YYYY-MM-DD");
      console.log("text", text);
      const day1 = moment(text.time)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      console.log("day1", day1);
      return (
        (this.month === this.currentMonth || today === day1) &&
        (text.reason === "5点25分之后5点半之前打卡，算早退5分钟" ||
          text.reason === "5分钟内迟到")
      );
    },
  },
};
</script>


<style lang="less" scoped>
.header {
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: #fff;
  padding: 12px;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;

    .value {
      font-weight: bold;
      font-size: 16px;
    }

    .btn {
      background-color: #0062ff;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .weeks {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e8f0f8;

    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .week {
      text-align: center;
      font-size: 13px;
      color: #667ba9;
    }
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #fff;
  border-radius: 4px;
  gap: 8px;
  padding: 12px;

  .day {
    border: 1px solid #e5eef8;
    border-radius: 4px;
    padding: 8px;
    min-height: 80px;
    cursor: pointer;

    .value {
      text-align: right;
    }

    .tag {
      margin-top: 4px;
      border-radius: 2px;
      color: #02a42f;
      background-color: #e6f8eb;
      text-align: center;
    }

    .red {
      border-radius: 2px;
      background-color: #ffebeb;
      color: #ff3535;
    }
  }

  .today {
    .value {
      color: #0062ff;
    }
  }
}
</style>